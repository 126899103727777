import config from "../config.json";
const { apiUrl } = config;
const userData = "user_data";

function storeLoginData(data) {
  localStorage.setItem(userData, JSON.stringify(data));
}
function getCurrentUser() {
  try {
    return JSON.parse(localStorage.getItem(userData));
  } catch (ex) {
    return null;
  }
}
async function logout() {
  localStorage.removeItem(userData);
}

export default {
  storeLoginData,
  getCurrentUser,
  logout,
};
