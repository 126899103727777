import "./LoginBeta.css";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import { Box } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import authService from "../../services/authService";
import { useEffect, useState } from "react";
import userServices from "../../services/userServices";
import { useNavigate } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
const LoginBeta = (props) => {

  let localUser = authService.getCurrentUser()?.data.data.user;
  let number = Math.floor(Math.random() * 100000 + 1);
  const [user, setUser] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [userinfo, setUserInfo] = useState();
  const [link, setLink] = useState();
  let navigate = useNavigate();

  useEffect(() => {
    try {
      const fetchData = async () => {
        let localUser = await authService.getCurrentUser()?.data.data.user;
        console.log(localUser);
        await userServices.getUserByEmail(localUser.email).then((res) => {
          if (!res.data.data.user[0]) {
            authService.logout();
            navigate("/");
          } else {
            setUserInfo(res.data.data.user[0]);
            Link(res.data.data.user[0].referral_Code);
            userServices.getRank(res.data.data.user[0]._id).then((res) => {
              let trimeUser = res.data.data.user;
              setUser(res.data.data.user);
              setIsLoading(false);
            });
          }
        });
      };
      fetchData();
    } catch (ex) {}
  }, []);

  const handleClick = (e) => {
    if (e.target.name === "twitter") {
      const url = encodeURIComponent(link);
      const message = encodeURIComponent(
        "This is mind-blowing. Brandfy.ai is changing brand design for the better. Check out this page!"
      );
      const twitterUrl = `https://twitter.com/intent/tweet?text=${message}&url=${url}`;
      window.open(twitterUrl, "_blank");
    } else {
      const url = encodeURIComponent(link);
     
const title = encodeURIComponent(
  "This is mind-blowing. Brandfy.ai is changing brand design for the better. Check out this page!"
);
const message = encodeURIComponent(
  "Check out this amazing page on Brandfy.ai!"
);
const body = encodeURIComponent(
  "This is mind-blowing. Brandfy.ai is changing brand design for the better. Check out this page!"
      );
      const description = encodeURIComponent("This page showcases the innovative features of Brandfy.ai.");


const linkedinShareUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${url}&title=${title}&summary=${message}&text=${body}&summary=${description}`;
      window.open(linkedinShareUrl, "_blank");

    }
  };
  const Link = (referral_Code) => {
      const ogUrlMetaTag = document.querySelector('meta[property="og:url"]');
    var referralCode = referral_Code;
    var urlWithReferralCode =
      "https://beta.brandfy.ai/?ref=" +
      encodeURIComponent(referralCode);
  ogUrlMetaTag.setAttribute("content", urlWithReferralCode);
    setLink(urlWithReferralCode);
  };
  
  useEffect(() => {
    if (!authService.getCurrentUser()) {
      navigate("/");
    }
  }, []);
  if (isLoading) {
    return (
      <Box
        sx={{
          display: "flex",
          height: "100vh",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <CircularProgress size={90} sx={{ color: "#fd5d5d" }} />;
      </Box>
    );
  }
  return (
    <Box className="login-beta-contain">
      <Box className="nav">
        <img
          src="brandfy_white_hor.svg"
          alt=""
          onClick={() => {
            navigate("/");
          }}
        />
        <button>JOIN BETA</button>
        <div className="crossicon">
          <CloseIcon className="icon betaicon"></CloseIcon>
        </div>
      </Box>

      <Box className="content">
        <Box className="paras">
          <Box className="paragraph">
            <p className="para1 par">
              Almost a beta user, {user ? `${user.name.trim()}!` : ""}
            </p>
          </Box>
          <Box className="paragraph2 par2 ">
            <p className="para2 parr betapara">
              Thanks for signing up for Brandfy! You're on our waitlist, and
              we'll notify you as soon as our product is ready. Get excited -
              we're looking forward to having you on board!
            </p>
          </Box>
        </Box>
        <Box className="form2">
          <Box className="boxx">
            <p className="place">YOUR PLACE</p>
            <p className="placeNumber">#{user ? `${user.rank}` : ""}</p>
          </Box>
          <p className="queue">Jump the queue</p>
          <p className="waitlist">
            Want to get to the top of our waitlist even faster? Share your
            personal link with your friends and family and you'll move up the
            list with each sign-up.
          </p>

          {/* <div className="refferBox">
            <Box className="line">
              <p className="refer">Refer 4 friends for a VIP onboarding</p>
              <p>1/4</p>
            </Box>
            <Box className="forline"></Box>
            <Box className="line">
              <p className="refer">Get 2 months free acces on launch!</p>
            </Box>
            <Box className="forline"></Box>
          </div> */}

          <div className="refferBox2">
            <Box className="line">
              <p className="referBox2">
                Refer 3 friends for a fast-track onboarding
              </p>
              <p>{user ? `${user.totalReferrals}` : ""}/3</p>
            </Box>
            <Box className="forlineBox2"></Box>
            <Box className="line lineMarg">
              <p className="refer">Get 2 months free access on launch!</p>
              <p>{user ? `${user.totalReferrals}` : ""}/8</p>
            </Box>
            <Box className="forlineBox2"></Box>
          </div>

          <p className="personalLinkagain">Your personal link</p>

          <div className="LinkBox2">
            <p className="personalLink">Your personal link</p>
            <input
              placeholder={link}
              className="inputPersonalLink"
              type="text"
              readOnly
            />
          </div>

          <input className="input1 alone linkinput" value={link} readonly />
          <Box className="shareButton">
            <button name="twitter" className="Twitter" onClick={handleClick}>
              {" "}
              <TwitterIcon className="twitterMobile" /> Share on Twitter
            </button>
            <button name="linkedin" className="linkedin" onClick={handleClick}>
              {" "}
              <LinkedInIcon className="LinkedinMobile" />
              Share on LinkedIn
            </button>
          </Box>
          <div></div>
          <></>
        </Box>
      </Box>
    </Box>
  );
};

export default LoginBeta;
