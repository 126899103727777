import http from "./httpService";
import config from "../config.json";
const { apiUrl } = config;

const apiEndpoint = apiUrl + "/users";

async function getUsers() {
  return http.get(`${apiEndpoint}`);
}

async function getUser(userId) {
  return http.get(`${apiEndpoint}/${userId}`);
}

async function createUser(userData) {
  return http.post(`${apiEndpoint}`, userData);
}

async function updateUser(userData) {
  return http.put(`${apiEndpoint}/${userData._id}`, userData);
}

async function deleteUser(userId) {
  return http.delete(`${apiEndpoint}/${userId}`);
}
async function getRank(userId) {
  return http.get(`${apiEndpoint}/rank/${userId}`);
}
async function getUserByEmail(userEmail) {
  return http.get(`${apiEndpoint}/email/${userEmail}`);
}
async function createRefUser(referral_Code, userData) {
  return http.post(
    `${apiEndpoint}/ref/?referral_Code=${referral_Code}`,
    userData
  );
}
export default {
  getUser,
  deleteUser,
  createUser,
  updateUser,
  getUsers,
  getRank,
  getUserByEmail,
  createRefUser,
};
