import "./LoginForm.css";
import React, { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { Box, Button } from "@mui/material";
import Joi from "joi";
import validationService from "../../services/validationService";
import { useNavigate } from "react-router-dom";
import userServices from "../../services/userServices";
import authService from "../../services/authService";
import CircularProgress from "@mui/material/CircularProgress";

var currentURL = new URL(window.location.href);
const LoginForm = () => {
  const [name, setFirstName] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [errors, setErrors] = useState({});
  const [apierror,setApiError]=useState()
  const schema = Joi.object().keys({
    name: Joi.string().required(),
    email: Joi.string()
      .email({ tlds: { allow: false } })
      .required(),
  });
  const handleChange =
    (setData) =>
    ({ currentTarget: input }) => {
      const errorMessage = validationService.validateProperty(input, schema);
      if (errorMessage) errors[input.name] = errorMessage;
      else delete errors[input.name];
      setErrors(errors);

      setData(input.value);
    };
  const handleKeyDown = (event) => {
    if (event.keyCode >= 48 && event.keyCode <= 57) {
      event.preventDefault();
    }
  };

  const handleSubmit = () => {
    let data = {
      name,
      email,
    };
    data.name = name.trim();
    const errors = validationService.validate(data, schema);
    setErrors(errors || {});
    if (errors) { setIsLoading(false); return console.log(errors) };

    doSubmit();
  };

  let navigate = useNavigate();

  const doSubmit = async () => {
    let data = {
      name,
      email,
      password: "Default@1234",
      address: "default ddress",
    };
    try {
      var referralCode = currentURL.searchParams.get("ref");
      if (referralCode) {
        console.log(referralCode);
        console.log(data);
        let response = await Promise.race([
          userServices.createRefUser(referralCode, data),
          new Promise((resolve, reject) =>
            setTimeout(reject, 60000, new Error("Network timed-out. Please try again in few moments"))
            
          ),
        ]);
        authService.storeLoginData(response);
        setIsLoading(false);
        navigate("/beta");
      } else {
        let response = await Promise.race([
          userServices.createUser(data),
          new Promise((resolve, reject) =>
            setTimeout(reject, 60000,  new Error("Network timed-out. Please try again in few moments"),setIsLoading(false))
          ),
        ]);
        authService.storeLoginData(response);
        setIsLoading(false);
        navigate("/beta");
      }
    } catch (ex) {
      console.log(ex.message);
      setIsLoading(false)
      setApiError("Error: " + ex.message)
    }
  };
  useEffect(() => {
    if (authService.getCurrentUser()) {
      navigate("/beta");
    }
  }, []);

  return (
    <Box className="contain">
      <Box className="login-form-nav">
        <div>
          <img src="brandfy_white_hor.svg" alt="" />
        </div>
        <CloseIcon className="icon iconform"></CloseIcon>
      </Box>
      <Box className="login-form-content">
        <Box className="login-form-paras">
          <Box className="login-form-paragraph">
            <p className="login-form-para1">Be the first to try our product</p>
            <p className="earlyAcc">Apply for early access</p>
          </Box>
          <Box className="login-form-paragraph2">
            <p className="login-form-para2">
              Be a part of something big! Join our Beta program and help us
              perfect the future of brand design. Sign up now and have a hand in
              shaping the industry.
            </p>
            <p className="currentBeta">Currently in Beta</p>
          </Box>
        </Box>
        <Box className="form">
          <p>First name</p>
          <input
            onKeyDown={handleKeyDown}
            name="firstName"
            placeholder="Peter"
            className="login-form-input1"
            type="text"
            value={name}
            onChange={handleChange(setFirstName)}
          />
           {errors.name && <span className="error">{errors.name}</span>}
          <p>Email address</p>
          <input
            name="email"
            placeholder="peter.gab@email.com"
            className="login-form-input1"
            type="text"
            value={email}
            onChange={handleChange(setEmail)}
          />
          {errors.email && <span className="error">{errors.email}</span>}
          <Button
            style={{ position: "relative" }}
            className="btn"
            onClick={(e) => {
              e.preventDefault();
              setIsLoading(true);
              handleSubmit();
            }}
            disabled={isLoading}
          >
            {isLoading ? (
              <>
                <CircularProgress
                  size={20}
                  color="inherit"
                  style={{ position: "absolute" }}
                />
              </>
            ) : (
              "JOIN BETA"
            )}
          </Button>
          {apierror && <span className="error">{apierror}</span>}
          <Box className="lastpara">
            By signing up, you agree to our <u>Privacy Policy</u> and{" "}
            <u>Terms of Service</u>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default LoginForm;
