import "./App.css";
import LoginForm from "./component/registration/LoginForm";
import LoginBeta from "./component/home/LoginBeta";
import { BrowserRouter, Routes, Route } from "react-router-dom";

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<LoginForm />} />
          <Route path="/beta" element={<LoginBeta />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
